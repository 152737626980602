<template>
  <div class="dashboard-container">
    <div class="tw-text-2xl tw-font-bold text-body">{{ $t(title) }}</div>
    <div>
      {{ $t(description) }}
    </div>
    <div
      v-if="loading"
      class="row-flex-justify-center"
    >
      <b-spinner label="Spinning" />
    </div>
    <div
      v-else
      class="__graph-container"
    >
      <div
        v-for="(row, index) in widgets"
        :key="index"
        class="bg-card tw-text-body tw-mt-5 widget-creation"
        :class="{ 'widget-creation-wrap': row.length >= 3 }"
        :style="setRowHeight(row)"
      >
        <div
          v-for="(widget, widgetIndex) in row"
          :key="widgetIndex"
          class="widget-creation__form"
        >
          <dashboard-chart-header
            v-if="typeof widget === 'object'"
            :data="widget"
            :indexes="{ parentIndex: index, childIndex: widgetIndex }"
          />
          <component
            :is="findChartComponent(widget.type)"
            class="widget-component"
            :type="widget.type"
            :result="widget.result"
            :chart-data="widget"
            :dashboard-id="widget.uuidKey"
            :for-preview="widget.type === 'TABLE'"
            :dashboard-filter-values="widget.type === 'TABLE' && widget.filters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataTable, graphMixin } from '@/mixins/dashboard'
import { dashboardService } from '@/services/dashboard'
import KpiChart from '@/components/shared/KpiChart.vue'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'
import ProzessChart from '@/components/chart/ProzessChart.vue'
import DashboardChartHeader from '@/components/Dashboard/DashboardChartHeader.vue'
import RootEvents from '@/constants/rootEvents'

export default {
  components: {
    KpiChart,
    WidgetDataSourceTable,
    ProzessChart,
    DashboardChartHeader,
  },

  mixins: [dataTable, graphMixin],
  data() {
    return {
      title: '',
      description: '',
      widgets: [],
      loading: false,
    }
  },

  watch: {
    '$route.params.id': {
      handler(newVal) {
        this.fetchData()
      },
    },
  },
  mounted() {
    this.fetchData()

    this.registerBusEvent(RootEvents.DASHBOARD_FILTER_APPLY, async payload => {
      const parentIndex = payload.indexes.parentIndex
      const childIndex = payload.indexes.childIndex
      const response = await this.$async(
        dashboardService('widget').getDashboardWidgetData(this.$route.params.id, payload.uuidKey, {
          page: 0,
          userFilterValues: payload.filter,
        }),
      )

      this.widgets[parentIndex][childIndex] = response.response.data
      this.$forceUpdate()
    })
  },

  methods: {
    setRowHeight(row) {
      const style = {
        height: 'auto',
      }

      const hasTable = row.some(item => item.type === 'TABLE')
      const hasChart = row.some(item => item.type === 'LINE' || item.type === 'BAR')
      if (hasTable) style.height = '650px'
      else if (hasChart) style.height = '600px'
      return style
    },
    findChartComponent(data) {
      const type = data.toLowerCase()
      if (type === 'kpi') return 'kpi-chart'
      if (type === 'line' || type === 'bar' || type === 'pie' || type === 'tree') return 'prozess-chart'
      return 'widget-data-source-table'
    },
    async fetchData() {
      this.loading = true
      this.widgets = []
      const { response } = await this.$async(dashboardService('dashboard').dashboarView(this.$route.params.id))
      const { data } = response
      this.title = data.name
      this.description = data.description

      this.widgets = data.widgetIds
      data.widgetIds.forEach(async (_widgets, index) => {
        const promise = _widgets.map(async (widget, childIndex) => {
          const widgetDataResponse = await this.$async(
            dashboardService('widget').getDashboardWidgetData(this.$route.params.id, widget.uuidKey, {
              page: 0,
            }),
          )
          this.widgets[index][childIndex] = widgetDataResponse.response.data
        })

        await Promise.all(promise)

        this.$forceUpdate()

        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';

$mobile-breakpoint: 958px;
$tablet-breakpoint: 1200px;
.dashboard-container {
  .widget-creation {
    position: relative;
    display: flex;
    &__form {
      background: var(--colour--card-bg);
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 0;
      border-radius: 15px;
      height: 100%;
      min-height: 200px;
      height: 100%;
      padding-bottom: 20px;
      overflow: hidden;
      .widget-component {
        overflow-x: auto;
        // overflow-y: hidden;
      }
      .kpi-widget-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 250px;
      }
    }
  }

  .result-table-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .widget-creation__form {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  @media (max-width: $tablet-breakpoint) {
    .widget-creation {
      flex-wrap: wrap;
      flex-direction: row;
      height: 100% !important;
      justify-content: center;
      align-items: stretch;
      .widget-creation__form {
        width: 100% !important;
        margin-bottom: 12px !important;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .widget-creation,
    .widget-creation-wrap {
      height: auto !important;
      display: flex;
      flex-direction: column;
      &__form {
        width: 100% !important;
      }
    }
  }
}
</style>
