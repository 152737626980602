<template>
  <b-modal
    id="advanced-search__save-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="sm"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header d-flex align-items-center tw-mb-6">
        <h4 class="mb-0">
          {{ $t('Switch filter to') }}
        </h4>
      </div>

      <b-overlay
        spinner-variant="primary"
        class="d-flex flex-column"
        variant="transparent"
        style="flex: 1"
      >
        <div class="search__form">
          <div
            v-for="(filter, index) in fields"
            :key="index"
            class="mb-2"
          >
            <!-- <prozess-input
              v-model="filter.paramValue"
              :field="filter.paramValue"
              :name="filter.paramValue"
              :placeholder="$case.title($t(filter.field.fieldKey))"
            /> -->
            <custom-input-field
              :ref="`customField-${index}`"
              :data="filter"
              :is-widget="false"
              :placeholder="$t(filter.details.label)"
              @fieldError="val => (typeof val === 'boolean' ? (filter.errorMessage = '') : (filter.errorMessage = val))"
              @input="val => (filter.paramValue = val)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center tw-mt-6">
          <button
            class="btn btn-secondary tw-ml-3 tw-px-4"
            type="button"
            style="width: 100px"
            @click="updateFitler()"
          >
            <span style="margin: 0 auto">{{ $t('Go') }}</span>
          </button>
          <button
            style="width: 100px"
            class="btn btn-dark tw-ml-3 tw-px-3"
            type="button"
            @click="open = false"
          >
            <span style="margin: 0 auto">{{ $t('Cancel') }}</span>
          </button>
        </div>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import fieldsManagementService from '@/services/advancedFieldMgmt'
import CustomInputField from '@/components/Dashboard/CustomInputField.vue'

export default {
  components: { CustomInputField },
  data() {
    return {
      fields: [],
      open: false,
      id: '',
      indexes: {},
    }
  },

  methods: {
    async updateFitler() {
      // await Promise.all(
      //   this.fields.forEach((field, index) => this.$refs[`customField-${index}`].validate()),
      // )
      // this.$refs.customField.validate()
      // Object.keys(this.$refs).forEach(el => {
      //   this.$refs[el][0].validate()
      // })
      if (this.fields.some(field => field.errorMessage)) {
        return false
      }
      const uuidKey = this.id
      const filter = this.fields.map(field => ({
        field: field.field,
        value: field.paramValue,
      }))

      this.$root.$emit('dashboardFilterApply', {
        uuidKey,
        filter,
        indexes: this.indexes,
      })

      this.open = false
    },

    async setData({ open, fields, id, indexes }) {
      const fieldWithTypes = await Promise.all(
        fields.map(async field => {
          const fieldKey = field.field
          const payload = {
            schema: fieldKey.schemaKey,
            table: fieldKey.tableKey,
            key: fieldKey.fieldKey,
          }
          const { response } = await this.$async(fieldsManagementService.getOne(payload))

          field.details = response.data
          field.errorMessage = ''
          return field
        }),
      )
      this.fields = fieldWithTypes
      this.open = open
      this.id = id
      this.indexes = indexes
    },
  },
}
</script>

<style></style>
