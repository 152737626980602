<template>
  <div class="widget-container__header">
    <div class="col-flex widget-container__header--title">
      <span class="tw-text-lg tw-font-bold line-clamp-1 text-body">{{ data.name }}</span>
      <span class="tw-text-md tw-font-bold line-clamp-2 text-muted">{{ data.description }}</span>
    </div>
    <feather-icon
      v-if="data.filters ? data.filters.length : false"
      icon="SlidersIcon"
      class="text-white cursor-pointer"
      size="23"
      @click="openFilterModal(data.filters)"
    />

    <DashboardFilterModal ref="filterModal" />
  </div>
</template>

<script>
import DashboardFilterModal from '@/components/Dashboard/DashboardFiilterModal.vue'

export default {
  components: { DashboardFilterModal },
  props: {
    data: {
      type: Object,
      required: true,
    },

    indexes: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openFilterModal(data) {
      this.$refs.filterModal.setData({
        open: true,
        fields: data,
        id: this.data.uuidKey,
        indexes: this.indexes,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';
.widget-container__header {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $colour--table-row-divider;
  padding: 10px 20px;
  &--title {
    max-width: 85%;
    word-wrap: break-word;
  }
}
</style>
